import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Home from './pages/Home'
import Minting from './pages/Minting'
import Assets from './pages/Assets'
import NoPage from "./pages/NoPage";
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import Roadmap from "./pages/Roadmap";
import HackerReports from "./pages/HackerReports";
import Assets2 from "./pages/Assets2";

function App() {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route element={< Home />} path='/'/>
                <Route element={< Minting />} path='/minting'/>
                <Route element={< Assets2 />} path='/your-legion'/>
                <Route element={< Roadmap />} path='/roadmap'/>
                <Route element={< HackerReports />} path='/hacker-reports'/>
                <Route path="*" element={< NoPage />}/>
            </Routes>
            <ToastContainer />
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
