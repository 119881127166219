import Connex from '@vechain/connex'
import {NODE, NETWORK, VENONYMOUS_CONTRACT_ADDRESS} from './'

const connex = new Connex({node: NODE, network: NETWORK})
const VenonymousContract = connex.thor.account(VENONYMOUS_CONTRACT_ADDRESS)

export {
    connex,
    VenonymousContract
}
